import React from 'react';

import { EventsListingNav } from './';
import { EventsListingCards } from './';

export const EventsListing = (data) => {
    const eventId = data.eventId;

    return (
        <>
            <EventsListingNav {...data} eventId={eventId} />
            <EventsListingCards {...data} />
        </>
    );
};

EventsListing.propTypes = {};
