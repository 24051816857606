import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    UPDATE_NAV_ITEM,
    UPDATE_FILTER_ITEM
} from 'store/modules/competition-nav/actions';
import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';

export const useNavSync = ({ seasons, initialValues, removeFinals }) => {
    const dispatch = useDispatch();
    const nav = useSelector(navSelector);

    const roundsFromStore = useSelector(
        (state) => state.rounds[`season_${nav.season.id}`]?.list
    );

    const rounds = removeFinals
        ? roundsFromStore?.filter(PULSE.app.common.match.isNonFinalRound)
        : roundsFromStore;

    /**
     * Batch reset all the "sub" filters together, when we change a main filter,
     * they are hidden so we don't want their settings to stay.
     */
    const resetFilters = useCallback(() => {
        dispatch(
            UPDATE_FILTER_ITEM.request({ filterItem: 'teams', value: [] })
        );
        dispatch(
            UPDATE_FILTER_ITEM.request({ filterItem: 'venues', value: [] })
        );
        dispatch(
            UPDATE_FILTER_ITEM.request({ filterItem: 'cities', value: [] })
        );
    }, [dispatch]);

    /**
     * Resets what the nav has stored as the current compseason
     */
    const resetSeason = useCallback(() => {
        dispatch(
            UPDATE_NAV_ITEM.request({
                navItem: 'season',
                value: { id: '' }
            })
        );

        /** Whenever we reset the seasons, we want to reset the sub-filters */
        resetFilters();
    }, [dispatch, resetFilters]);

    // Handle all Competition filter updates and synchronisation
    useEffect(() => {
        /**
         * If competition is blank set competition to the compSeason selected in
         * the widget config
         */
        if (nav.competition.id === '') {
            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'competition',
                    value: {
                        id: Number(initialValues.competitionId),
                        providerId: initialValues.competitionProviderId
                            ? initialValues.competitionProviderId
                            : ''
                    }
                })
            );
        }

        /**
         * If the competition changes and the seasons don't match or are empty, then
         * reset the nav's season to empty, the useEffect above will handle setting
         * it once the seasons get populated for the new competition
         */
        if (nav.competition.id !== '') {
            // If there are no seasons loaded yet, then empty the nav season & teams settings
            if (nav.season.id === '' && !seasons?.length) {
                resetSeason();
                /**
                 * Else if seasons have loaded and they don't match, then also
                 * reset the nav season & teams setting
                 */
            } else if (
                seasons &&
                seasons[0]?.competitionId !== nav.competition.id
            ) {
                resetSeason();
                /**
                 * Else if seasons have loaded and they match the new comp but the
                 * current selected season in the nav isn't in the new list of
                 * seasons, then also reset.
                 */
            } else if (
                seasons &&
                !seasons.find((season) => season.id === nav.season.id)
            ) {
                resetSeason();
            }
        }
    }, [
        dispatch,
        initialValues.competitionId,
        initialValues.competitionProviderId,
        nav.competition.id,
        nav.season.id,
        resetSeason,
        resetFilters,
        seasons
    ]);

    useEffect(() => {
        if (nav.season.id !== '') {
            if (nav.round.seasonId && nav.season.id !== nav.round.seasonId) {
                dispatch(
                    UPDATE_NAV_ITEM.request({
                        navItem: 'round',
                        value: { id: '' }
                    })
                );
            }
        }
    }, [dispatch, nav.round.seasonId, nav.season.id]);

    /**
     * Handle synchronisation of the season and round fields
     */
    useEffect(() => {
        /**
         * set season to the compSeason selected in the widget config and watch
         * for when the season is empty and matches the competition id, this
         * happens when the competition is changed
         */
        if (
            nav.season.id === '' &&
            seasons?.length &&
            seasons[0]?.competitionId === nav.competition.id
        ) {
            // Is the widget config set season in the list of loaded seasons?
            const initialCompSeason = seasons.find(
                (compSeason) => compSeason.id === Number(initialValues.seasonId)
            );

            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'season',
                    value: {
                        id: initialCompSeason?.id
                            ? Number(initialValues.seasonId)
                            : seasons[0].id,
                        providerId: initialCompSeason
                            ? initialValues.seasonPid
                            : seasons[0].providerId
                    }
                })
            );
        }

        // set round to the current round number for the first selected season
        if (
            !(nav.round?.id && nav.round?.seasonId) &&
            seasons?.length &&
            nav.season.id
        ) {
            const selectedCompSeason = seasons.find(
                (compSeason) => compSeason.id === nav.season.id
            );
            if (selectedCompSeason && rounds?.length) {
                let selectedRound;

                if (!nav.round?.id) {
                    selectedRound =
                        rounds
                            .filter((round) => {
                                return removeFinals
                                    ? PULSE.app.common.match.isNonFinalRound
                                    : true;
                            })
                            .find(
                                (round) =>
                                    round.roundNumber ===
                                    selectedCompSeason.currentRoundNumber
                            ) || rounds[removeFinals ? rounds.length - 1 : 0];
                } else if (nav.round?.id && !nav.round?.seasonId) {
                    selectedRound = rounds.find(
                        (round) => round.id === nav.round.id
                    );
                }

                dispatch(
                    UPDATE_NAV_ITEM.request({
                        navItem: 'round',
                        value: selectedRound
                    })
                );
            }
        }
    }, [
        dispatch,
        initialValues.competitionId,
        initialValues.seasonId,
        initialValues.seasonPid,
        nav.competition.id,
        nav.round,
        nav.season.id,
        seasons,
        rounds,
        removeFinals
    ]);
};
