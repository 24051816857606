import React from 'react';
import PropTypes from 'prop-types';

import { Tag, ShareButton } from './';
import { getEventTagProperties } from '../../utils/get-event-tag-properties';

import { useGetTeamsQuery } from 'common/store/modules/api/afl-api';
import { LazyImage } from 'common/react/components/LazyImage';

export const Card = ({
    event,
    localTimezone,
    compseasonId,
    activeSharePopup,
    setActiveSharePopup
}) => {
    const timezone = localTimezone ? localTimezone : 'Australia/Melbourne';
    const dateFormat = 'h:mm a';
    const tags = [];

    const eventTag = PULSE.app.common.events.getEventTag(event, 'EventType:');

    const eventTagProperties = getEventTagProperties(eventTag)
        ? getEventTagProperties(eventTag)
        : {
              name: 'Default',
              icon: 'default'
          };

    const { data: teamsList = [] } = useGetTeamsQuery(compseasonId, {
        skip: !compseasonId
    });

    if (eventTag) {
        tags.push({
            name: eventTag,
            icon: eventTagProperties.icon
        });
    }

    if (event.references.length && teamsList.length) {
        const teamRefs = event.references.map((ref) =>
            ref.contentType === 'AFL_TEAM' ? parseInt(ref.reference) : null
        );

        teamsList.forEach((team) => {
            if (teamRefs.includes(team.id)) {
                tags.push({
                    name: team.name
                });
            }
        });
    }

    // get event module items
    const detailsModule =
        event.modulesInContent?.find(
            (item) => item.title.toLowerCase().replace(' ', '') === 'details'
        ) ?? null;

    const cardLink = detailsModule
        ? PULSE.app.common.events.getModuleItem(detailsModule, 'Link')
        : '';

    const shareLink = detailsModule
        ? PULSE.app.common.events.getModuleItem(detailsModule, 'Share Link')
        : '';

    const ticketsLink = detailsModule
        ? PULSE.app.common.events.getModuleItem(detailsModule, 'Tickets Link')
        : '';

    return (
        <>
            <div className="events-listing__card">
                {cardLink ? (
                    <a
                        href={cardLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="events-listing__card-link"
                        aria-label={event.title}
                    ></a>
                ) : (
                    <></>
                )}

                <div className="events-listing__time u-hide-until-tablet">
                    <span>
                        <span>
                            {moment(event.startDate)
                                .tz(timezone)
                                .format(dateFormat)}
                        </span>{' '}
                        -{' '}
                        <span>
                            {moment(event.endDate)
                                .tz(timezone)
                                .format(dateFormat)}
                        </span>
                    </span>
                </div>
                <div className="events-listing__meta">
                    <div className="events-listing__meta-divider"></div>

                    <div className="events-listing__meta-details">
                        <div className="events-listing__time u-hide-from-tablet">
                            <span>
                                <time>
                                    {moment(event.startDate)
                                        .tz(timezone)
                                        .format(dateFormat)}
                                </time>{' '}
                                -{' '}
                                <time>
                                    {moment(event.endDate)
                                        .tz(timezone)
                                        .format(dateFormat)}
                                </time>
                            </span>
                        </div>

                        <p className="events-listing__title">{event.title}</p>
                        <p className="events-listing__subtitle">
                            {event.subTitle}
                        </p>

                        {tags.length ? (
                            <div className="events-listing__tags">
                                {tags.map((tag) => (
                                    <Tag key={tag.name} {...tag} />
                                ))}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="events-listing__meta-extras">
                        <div className="events-listing__meta-extras-thumbnail">
                            {event.leadMedia && (
                                <LazyImage
                                    url={event.leadMedia.imageUrl}
                                    objectFit={true}
                                />
                            )}
                        </div>
                        <ShareButton
                            eventId={event.id}
                            activeId={activeSharePopup}
                            setActiveSharePopup={setActiveSharePopup}
                            shareLink={shareLink}
                            ticketsLink={ticketsLink}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

Card.propTypes = {
    event: PropTypes.object.isRequired,
    localTimezone: PropTypes.string,
    compseasonId: PropTypes.string,
    activeSharePopup: PropTypes.any,
    setActiveSharePopup: PropTypes.func
};
