import { Fragment, createElement } from 'react';
import { createRoot } from 'react-dom/client';
import { createPortal } from 'react-dom';

import {
    DefaultTableProvider,
    DefaultTableModalProvider,
    LadderTableProvider,
    PlayerStatsTableProvider
} from '../../custom-table/js/providers';
import { PlayerStatsProvider } from '../../mc-player-stats/js/providers/PlayerStatsProvider';
import BrownlowLeaderboardEmbeddableProvider from '../../brownlow-tracker/js/providers/brownlow-leaderboard-embeddable';
import { FixturesProvider } from '../../fixtures/js/providers';
import { LadderProvider } from '../../ladder-v2/js/providers';
import { MainMenuRegionalDisplayProvider } from 'widgets/main-navigation/js/providers/MainMenuRegionalDisplayProvider';
import { RegionSelectionOptionsProvider } from 'widgets/region-selector/js/provider/RegionSelectionOptionsProvider';
import { SelectionCalloutProvider } from 'widgets/selection-callout/js/provider/SelectionCalloutProvider';
import { EventsListingProvider } from 'widgets/events-listing/js/providers';
import { TeamAnnouncementsProvider } from 'widgets/team-announcements/js/providers/TeamAnnouncementsProvider';
import { TASponsorProvider } from 'widgets/team-announcements/js/providers/TASponsorProvider';

(function (app) {
    /**
     * renderMap will contain a mapping of React "Provider" components to DOM selectors.
     * The React "component" will be rendered into any DOM elements matching the "node" selector.
     */
    const renderMap = [
        {
            node: '.js-react-default-table-provider',
            component: DefaultTableProvider
        },
        {
            node: '.js-react-default-table-modal-provider',
            component: DefaultTableModalProvider
        },
        {
            node: '.js-react-custom-ladder-provider',
            component: LadderTableProvider
        },
        {
            node: '.js-react-player-stats-table-provider',
            component: PlayerStatsTableProvider
        },
        {
            node: '.js-brownlow-leaderboard',
            component: BrownlowLeaderboardEmbeddableProvider
        },
        {
            node: '.js-react-player-stats-provider',
            component: PlayerStatsProvider
        },
        {
            node: '.js-react-fixtures',
            component: FixturesProvider
        },
        {
            node: '.js-react-ladder',
            component: LadderProvider
        },
        {
            node: '.js-regional-display',
            component: MainMenuRegionalDisplayProvider
        },
        {
            node: '.js-regional-options-display',
            component: RegionSelectionOptionsProvider
        },
        {
            node: '.js-selection-callout',
            component: SelectionCalloutProvider
        },
        {
            node: '.js-events-listing',
            component: EventsListingProvider
        },
        {
            node: '.js-team-announcements',
            component: TeamAnnouncementsProvider
        },
        {
            node: '.js-ta-sponsor',
            component: TASponsorProvider
        }
    ];

    /**
     * Filter a list of DOM nodes to those that exist on the page,
     * Get a reference to the Nodes and map them to a React component,
     * Create a React Portal for each Node (so we can render React components into the same React tree, but not the same DOM tree).
     *
     * history can be used to pass in a reference to the value of the `history/createBrowserHistory` package function,
     * in case we need a package like "connected-react-router" to sync router state with the redux store.
     *
     * @param renderMapArr
     * @param history
     */
    const render = (renderMapArr, history) => {
        const children = renderMapArr
            .filter((item) => document.querySelector(item.node))
            .map((item) =>
                [...document.querySelectorAll(item.node)].map((node) => ({
                    node,
                    component: item.component
                }))
            )
            .flat()
            .map((item) => {
                return createPortal(
                    // we could alternatively spread "data" here if we don't want to nest any attributes
                    createElement(item.component, {
                        store: app.redux.store,
                        history,
                        data: item.node.dataset
                    }),
                    item.node
                );
            });

        // if we have any matching DOM selectors, render the matching React components
        if (children.length) {
            const container = document.createElement('div');
            const root = createRoot(container);
            root.render(createElement(Fragment, null, children));
        }
    };

    // wait for common to load, then run the bootstrap process
    new Promise((resolve) => {
        window.isCommonLoaded === true
            ? resolve()
            : window.addEventListener(
                  app.common.CONSTANTS.EVENTS.SCRIPTS.COMMON.LOADED,
                  resolve
              );
    }).then(() => render(renderMap));
})(PULSE.app);
