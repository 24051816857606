import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { navSelector } from 'common/store/modules/events-listing/selectors';
import {
    UPDATE_NAV_ITEM,
    UPDATE_FILTER_ITEM
} from 'common/store/modules/events-listing/actions';
import { useGetEventsQuery } from 'common/store/modules/api/content-api';

export const Date = ({ eventId, localTimezone, resetFilters }) => {
    const dispatch = useDispatch();
    const nav = useSelector(navSelector);

    const timezone = localTimezone ? localTimezone : 'Australia/Melbourne';

    // Pass empty params to match a request used in the listing when filtering by team
    const { data: events = [] } = useGetEventsQuery({
        eventId: eventId,
        params: {
            tagNames: [],
            contentFilter: ''
        }
    });

    const dates = useMemo(() => {
        return PULSE.app.common.events.getEventDates(events, timezone, true);
    }, [events]);

    let currentDate = moment().tz(localTimezone).format('YYYY/MM/DD');

    useEffect(() => {
        // set initial item
        // useful on page load
        let found = dates.find((item) => item === currentDate);
        let postDate = moment(currentDate).isAfter(
            moment(dates[dates.length - 1])
        );

        if (found) {
            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'date',
                    value: found
                })
            );
        } else if (postDate) {
            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'date',
                    value: dates[dates.length - 1]
                })
            );
        } else {
            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'date',
                    value: dates[0]
                })
            );
        }
    }, [dates]);

    return (
        <div className="events-listing-nav__date">
            <ul className="events-listing-nav__date-list">
                {dates.map((date) => (
                    <li
                        key={date.replace('/', '')}
                        className="events-listing-nav__date-item"
                    >
                        <button
                            className={`events-listing-nav__date-button ${
                                nav.date === date &&
                                !nav?.filters?.eventTypes?.length > 0 &&
                                !nav?.filters?.teams?.length > 0
                                    ? 'is-active'
                                    : ''
                            }`}
                            onClick={(event) => {
                                event.preventDefault();
                                dispatch(
                                    UPDATE_NAV_ITEM.request({
                                        navItem: 'date',
                                        value: date
                                    })
                                );
                                dispatch(
                                    UPDATE_FILTER_ITEM.request({
                                        filterItem: 'eventTypes',
                                        value: []
                                    })
                                );
                                dispatch(
                                    UPDATE_FILTER_ITEM.request({
                                        filterItem: 'teams',
                                        value: []
                                    })
                                );
                                resetFilters();
                            }}
                        >
                            <time>{moment(date).format('ddd MMM D')}</time>
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

Date.propTypes = {
    eventId: PropTypes.string.isRequired,
    localTimezone: PropTypes.string,
    resetFilters: PropTypes.func
};
