import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Card } from './cards';
import { EmptyState } from 'common/react/components/EmptyState';

export const EventsListingByDate = ({
    eventsArray,
    localTimezone,
    compseasonId,
    activeStartDate
}) => {
    const [activeSharePopup, setActiveSharePopup] = useState('');

    return (
        <div className="events-listing wrapper">
            {eventsArray.length ? (
                <>
                    <div className="events-listing__header">
                        <p>{moment(activeStartDate).format('dddd MMMM D')}</p>
                        <p>
                            &nbsp;
                            {`- ${moment(activeStartDate).format('YYYY')}`}
                        </p>
                    </div>

                    {eventsArray.map((event) => (
                        <Card
                            key={event.id}
                            localTimezone={localTimezone}
                            event={event}
                            compseasonId={compseasonId}
                            activeSharePopup={activeSharePopup}
                            setActiveSharePopup={setActiveSharePopup}
                        />
                    ))}
                </>
            ) : (
                <EmptyState />
            )}
        </div>
    );
};

EventsListingByDate.propTypes = {
    eventsArray: PropTypes.array.isRequired,
    localTimezone: PropTypes.string,
    compseasonId: PropTypes.string,
    activeStartDate: PropTypes.string
};
